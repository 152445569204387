const GoalsData = [
  {
    name: "Blog",
    description: "Blog articles",
    target: 12,
  },
  {
    name: "Project",
    description: "Tech projects",
    target: 3,
  },
  {
    name: "Course",
    description: "Courses",
    target: 3,
  },
];

export default GoalsData;
